import React from "react";
import { graphql } from "gatsby";

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";

function BestBreweriesAu({ data }) {
  const stateData = data.file.childDataJson.auprovences.sort();

  return (
    <Layout>
      <SEO
        title="Lists of the Best Breweries | Finding Beer"
        keywords={[`breweries`, `top breweries`, `lists`, `finding.beer`]}
      />
      <section className="bg-white border-b py-8 mt-10 sm:p-20 px-4">
        <div className="container max-w-5xl mx-auto m-8">
          <ul className="list-decimal text-sm text-center">
            <li className="inline-block mr-2"><a href="/best-breweries/" className="underline">Brewery Lists</a></li> /
            <li className="inline-block mr-2 ml-2">Canada</li>
          </ul>

          <h2 className="w-full my-2 mt-8 text-5xl font-bold leading-tight text-center text-gray-800">
            Best Breweries By Australian Province
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          {stateData.map((data, idx) => (
            <div class="flex justify-center">
              <a
                className="rounded-lg shadow-lg bg-white max-w-sm hover:bg-slate-100 block"
                href={`/best-breweries/australia/${data.toLowerCase().replace(/ /g, "-")}/`}
              >
                <img
                  class="rounded-t-lg"
                  src={`https://cdn.finding.beer/static/images/places/sm/${data}.jpg`}
                  alt={`Image of ${data}`}
                  loading="lazy"
                />

                <div class="p-6 text-center">
                  <h5 class="text-gray-900 text-xl font-medium mb-2">{data}</h5>
                </div>
              </a>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
}

export default BestBreweriesAu;

export const query = graphql`
  query {
    file(name: { eq: "allPlaces" }) {
      childDataJson {
        auprovences
      }
    }
  }
`;
